@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html, body, #root {
    height: 100%;
}

.content {
    width: 306px;
    height: 195px;
    background: #f0f0f0;
    padding: 0;
    overflow: hidden;
}
  
.wrapper {
    /* padding: 10px; */
    /* height: 432px; */
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: top left;
}